import { action, makeObservable, observable } from 'mobx';

import { LatLngExpression } from 'leaflet';

import ControlPointsApiService from 'Services/APIServices/ControlPointsApiService';

import ControlPoint from 'Shared/Models/ControlPoint';

const controlPointsApiService = new ControlPointsApiService('/');

class MapStore {
  public waiting = false;

  /* Точка, отвечающая за положение центра карты, при запуске центр должен
      установиться по текущим координатам коробля.
      Но если данные не получены, то центр остается в центре Европы*/
  public center: LatLngExpression = [49.951, 18.1];

  public road: ControlPoint[] = [];

  public separatedRoad: Array<ControlPoint[]> = [];
  public isPassedCalculating: boolean = false;

  constructor() {
    makeObservable(this, {
      waiting: observable,
      center: observable,
      road: observable,
      separatedRoad: observable,
      isPassedCalculating: observable,

      setCenter: action,
      getRoad: action,
      getSeparatedRoad: action,
      setIsPassedCalculating: action,
    });
  }

  /* Метод, который сохраняет в стор все контрольные точки маршрута*/
  public async getRoad() {
    try {
      this.waiting = true;

      const { data } = await controlPointsApiService.getAllPoints();
      if (!data) return;

      this.road = data.map(item => new ControlPoint(item));

      this.setCenter([this.road[0].longitude, this.road[0].latitude]);
    } catch (e) {
    } finally {
      this.waiting = false;
    }
  }

  /* Метод, который сохраняет в стор все контрольные точки маршрута, разделенные по ОТДЕЛЬНЫМ полилиниям*/
  public async getSeparatedRoad() {
    try {
      this.waiting = true;

      const { data } = await controlPointsApiService.getSortedPoints();
      if (!data) return;

      this.separatedRoad = data.map((line, idx) => line.map(point => new ControlPoint(point)));

      this.setCenter([this.separatedRoad[0][0].latitude, this.separatedRoad[0][0].longitude]);
    } catch (e) {
    } finally {
      this.waiting = false;
    }
  }

  /* Устанавливает центр карты по заданным координатам*/
  public setCenter(centerCoordinates: LatLngExpression) {
    this.center = centerCoordinates;
  }

  public setIsPassedCalculating(value: boolean){
    this.isPassedCalculating = value;
  }
}

export default new MapStore();
