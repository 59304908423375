import moment from 'moment';
import 'moment/locale/ru';

import MainPage from 'Modules/MainPage';

import './App.css';

moment.locale('ru');

function App() {
  return (
    <div className="App">
      <MainPage />
    </div>
  );
}

export default App;
