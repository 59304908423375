import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  mapContainer: {
    width: '100%',
    height: '100%',
    zIndex: 0,
  },
  pointPopup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      margin: '5px 0!important',
    },
  },
  headerPopup: {
    margin: 0,
    color: '#aaa',
    fontSize: 14,
  },
  countryPopup: {
    fontWeight: 700,
  },
  datePopup: {
    color: '#aaa',
    fontSize: 14,
  },
}));

export default useStyles;
