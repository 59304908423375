import { observer } from 'mobx-react';

import { notesStore } from 'Stores';

import useStyles from './styles';
import NoteItem from './Note/NoteItem';
import rubiusLogo from 'assets/rubius_logo.png';

interface IProps {
  isOpend: boolean;
}

const NotesBar = ({ isOpend }: IProps) => {
  const c = useStyles();

  return (
    <div className={isOpend ? c.visible : c.hidden}>
      <div className={c.barHeader}>Дневник капитана</div>
      <div className={c.notesWrapper}>
        {!!notesStore.orderedNotes.length &&
          notesStore.orderedNotes.map(noteItem => <NoteItem key={noteItem.id} note={noteItem} />)}
        {!notesStore.orderedNotes.length && <div className={c.emptyNotes}>Нет контрольны точек...</div>}
      </div>

      <div className={c.linkToDeveloper}>
        <a href="https://rubius.com/" target="_blank" rel="noreferrer" className={c.link}>
          &copy; 2021 Разработано Rubius <img className={c.linkIcon} src={rubiusLogo} alt="" />
        </a>
      </div>
    </div>
  );
};

export default observer(NotesBar);
