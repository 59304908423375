import React from 'react';
import { observer } from 'mobx-react';

import { Card, Button, CardContent, Typography, Zoom } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';

import { commonStore } from 'Stores';

import ControlPoint from 'Shared/Models/ControlPoint';
import Note from 'Shared/Models/Note';

import useStyles from './styles';
import clsx from 'clsx';

const InfoPanel = () => {
  const c = useStyles();

  return (
    <Zoom in={commonStore.isInfoOpend} mountOnEnter unmountOnExit timeout={{ enter: 550, exit: 350 }}>
      <div className={c.noteInfo}>
        <Card className={c.card}>
          <Button
            size="small"
            color="secondary"
            className={c.closeBtn}
            onClick={() => commonStore.setIsInfoOpened(false)}>
            <CloseOutlined />
          </Button>

          <CardContent className={c.cardContent}>
            <div>
              <Typography color="textSecondary" gutterBottom>
                {commonStore.infoPoint instanceof ControlPoint ? 'Контрольная точка' : 'Запись из дневника'}
              </Typography>

              <h1 className={c.header}>
                {commonStore.infoPoint instanceof ControlPoint
                  ? commonStore.infoPoint.country
                  : commonStore.infoPoint?.heading}
              </h1>

              {commonStore.infoPoint instanceof Note && (
                <Typography className={c.subTitle} color="textSecondary" gutterBottom>
                  Дата создания: {commonStore.infoPoint?.noteDateString}
                </Typography>
              )}
            </div>

            {commonStore.infoPoint instanceof Note && (
              <div className={c.cardInfos}>
                <div className={clsx(c.cardPanel, c.temperatureCard)}>
                  <div className={c.wrapper}></div>
                  <div className={c.cardInfosContent}>
                    <div>Погода за бортом:</div>
                    <div className={clsx(c.cardValue,c.weatherGroup)}>
                      <img
                        src={`https://openweathermap.org/img/wn/${commonStore.infoPoint.noteWeatherIcon}.png`}
                        alt="weather"
                      />
                      <>{commonStore.infoPoint.noteTemperatureString}</>
                    </div>
                    <a className={c.geoSource} href="https://openweathermap.org/" target='blank' >openweathermap.org</a>
                  </div>
                </div>

                <div className={clsx(c.cardPanel,c.depthCard)}>
                  <div className={c.wrapper}></div>
                  <div className={c.cardInfosContent}>
                   <div>Глубина океана:</div>
 
                    <div className={c.cardValue}>
                      {commonStore.infoPoint?.depth ? (
                        Math.abs(commonStore.infoPoint.depth) + ' м'
                      ) : (
                        <span className={c.noDepth}>не измерено</span>
                      )}
                    </div>

                    <a className={c.geoSource} href="https://elevation.racemap.com" target='blank'>elevation.racemap.com</a>
                  </div>
                </div>
              
                <div className={clsx(c.cardPanel,c.daysCard)}>
                  <div className={c.wrapper}></div>

                  {commonStore.infoPoint.dateBefore && (
                    <div className={c.cardInfosContent}>
                      <div>Дней в пути:</div>
                      <div className={c.cardValue} style={{marginBottom: 30}}>{commonStore.infoPoint.dateBefore}</div>
                    </div>
                  )}
                </div>

                <div className={clsx(c.cardPanel,c.distanceCard)}>
                  <div className={c.wrapper}></div>
                  {commonStore.infoPoint.dateBefore && (
                    <div className={c.cardInfosContent}>
                      <div>Пройдено с предыдущей точки:</div>
                      <div className={c.cardValue} style={{marginBottom: 30, marginTop: 0,}}>{commonStore.infoPoint.distanceFromPrev} км</div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className={c.descCard}>
              <h3 className={c.descHeader}>Описание</h3>
              <div
                className={c.descBody}
                dangerouslySetInnerHTML={{
                  __html: commonStore.infoPoint?.description ?? '',
                }}></div>
            </div>

          </CardContent>
        </Card>
      </div>
    </Zoom>
  );
};

export default observer(InfoPanel);
