import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  toolTip: {
    fontSize: '17px',
  },
  mapContainer: {
    width: '100%',
    height: '100%',
    zIndex: 0,
  },
  diaryButton: {
    position: 'absolute',
    zIndex: 1,
    top: '15px',
    left: '100%',
    transform: 'translateX(calc(-100% - 15px))',
  },
}));

export default useStyles;
