import { useState } from 'react';
import clsx from 'clsx';

import { Button, Grid, Slide } from '@material-ui/core';
import { StyledTooltip } from 'Modules/MainPage/MainPage';

import { CloseOutlined } from '@material-ui/icons';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import sheepPointMarker from 'assets/sheep.svg';
import flagIcon from 'assets/flag.svg';
import pinIcon from 'assets/pin.svg';

import { COLORS } from 'Shared/Constants/Colors';
import useStyles from './styles';
const MapLegend = () => {
  const c = useStyles();

  const [showLegend, setShowLegend] = useState(true);
  
  const onChangeLegendVisibility = () => {
    setShowLegend(!showLegend);
  };

  return (
    <>
      {!showLegend && (
        <StyledTooltip title='Открыть легенду' placement="left">
            <Button
              className={clsx(c.bottomPosition, c.legendButton)}
              variant="contained"
              onClick={onChangeLegendVisibility}>
              <HelpOutlineIcon style={{ color: 'gray' }}/>
            </Button>
         </StyledTooltip>
      )}

      <Slide in={showLegend} direction="left" mountOnEnter unmountOnExit timeout={{ enter: 550, exit: 350 }}>
        <div className={clsx(c.content, c.bottomPosition)}>
          <StyledTooltip title='Закрыть' placement="left">
            <Button
              className={c.closeButton}
              onClick={onChangeLegendVisibility}>
              <CloseOutlined style={{ color: 'gray' }} />
            </Button>
          </StyledTooltip>
          
          <Grid container spacing={1}>
            {/* TODO (BorukhinaKO) решено скрыть на данном этапе */}
            {/* <Grid container item xs={12} spacing={3} className={c.statusRow}>
              <span className={c.line} style = {{ color: COLORS.PointLine}}   />
              <div className={c.name}>Предполагаемый маршрут</div>
            </Grid> */}

            <Grid container item xs={12} spacing={3} className={c.statusRow}>
              <span  className={c.line} style = {{ color: COLORS.NoteLine}}   />
              <div className={c.name}>Фактический маршрут</div>
            </Grid>

            <Grid container item xs={12} spacing={3} className={c.statusRow}>
              <span className={c.dot} style = {{backgroundColor: COLORS.PointColor}} />
              <div className={c.name}>Точка маршрута</div>
            </Grid>

            <Grid container item xs={12} spacing={3} className={c.statusRow}>
              <img className={c.legendIcon} src={pinIcon} alt="" />
              <div className={c.name}>Посещённая точка маршрута</div>
            </Grid>

            <Grid container item xs={12} spacing={3} className={c.statusRow}>
              <img className={c.legendIcon} src={flagIcon} alt="" />
              <div className={c.name}>Запись дневника</div>
            </Grid>
            
            <Grid container item xs={12} spacing={3} className={c.statusRow}>
                <img className={c.legendIcon} src={sheepPointMarker} alt="" />
                <div className={c.name}>Текущее местоположение</div>
            </Grid>
          </Grid>
        </div>
      </Slide>
          
    </>
  );
};

export default MapLegend;
