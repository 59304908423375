import { observer } from 'mobx-react';

import L, { LatLngBounds } from 'leaflet';
import { LatLngExpression, LatLng } from 'leaflet';
import { MapContainer, Polyline, LayersControl, TileLayer, useMap, Marker, Popup, Circle } from 'react-leaflet';

import { Button, Typography } from '@material-ui/core';

import { mapStore, notesStore, commonStore } from 'Stores';

import sheepPointMarker from 'assets/sheep.svg';
import flagIcon from 'assets/flag.svg';
import pinIcon from 'assets/pin.svg';

import 'leaflet/dist/leaflet.css';
import useStyles from './styles';
import { Fragment } from 'react';
import { COLORS } from 'Shared/Constants/Colors';
import Note from 'Shared/Models/Note';
import ControlPoint from 'Shared/Models/ControlPoint';

const sheepPointIcon = new L.Icon({
  iconUrl: sheepPointMarker,
  iconRetinaUrl: sheepPointMarker,
  popupAnchor: [-0, -0],
  iconSize: [32, 42],
  iconAnchor: [16, 21]
});

const pinPointIcon = new L.Icon({
  iconUrl: pinIcon,
  iconRetinaUrl: pinIcon,
  popupAnchor: [-0, -0],
  iconSize: [28, 36],
  iconAnchor: [14, 18]
});

const bookPointIcon = new L.Icon({
  iconUrl: flagIcon,
  iconRetinaUrl: flagIcon,
  popupAnchor: [-0, -0],
  iconSize: [28, 38],
  iconAnchor: [14, 19]
});

const Map = () => {
  const c = useStyles();

  const SetCenter = ({ coords, isPassedCalculating }: { coords: LatLngExpression, isPassedCalculating: boolean }) => {
    const map = useMap();
    map.setView(coords);

    // TODO (Borukhina KO) продумать увеличение иконок при масштабировании
    //  const [zoom, setZoomLevel] = useState(3);
    // в иконки добавить:
    // iconSize: [(8 + zoom) * (zoom / 2), 38],
    // iconAnchor: [(8 + zoom) * (zoom / 2) / 2, 19]
    // const mapEvents = useMapEvents({
    //   zoomend: () => {
    //     setZoomLevel(mapEvents.getZoom());
    //   },
    // });

    if (isPassedCalculating || mapStore.separatedRoad.length === 0) return null;

    let isDistanceCalculated = false;

    for (let k = 0; k < mapStore.separatedRoad.length; k++) {
      for (let i = 0; i < mapStore.separatedRoad[k].length; i++) {
        for (let j = 0; j < notesStore.allNotes.length; j++) {
          if (
            map.distance(
              [mapStore.separatedRoad[k][i].latitude, mapStore.separatedRoad[k][i].longitude],
              [notesStore.allNotes[j].latitude, notesStore.allNotes[j].longitude]
            ) < 100000
          ) {
            mapStore.separatedRoad[k][i].updateIsPassed(true);
          }

          if (!isDistanceCalculated) {
            // обновим расстояние от предыдущей точки
            if (j !== 0) {
              const distance = map.distance(
                [notesStore.allNotes[j-1].latitude, notesStore.allNotes[j-1].longitude],
                [notesStore.allNotes[j].latitude, notesStore.allNotes[j].longitude],
              )
              notesStore.allNotes[j].updateDistance(distance);
            }
          }
        }
        isDistanceCalculated = true;
      }
    }
    mapStore.setIsPassedCalculating(true);

    return null;
  };

  const onDairyNoteClick = (note: Note) => {
    mapStore.setCenter(new LatLng(note.latitude, note.longitude));

    commonStore.setActivePoint(note.id);
    notesStore.setIsNotesBarOpen(true);
  };

  const onControlPointClick = (point: ControlPoint) => {
    mapStore.setCenter(new LatLng(point.latitude, point.longitude));
  };

  const onOpenControlPointClick = (point: ControlPoint) => {
    commonStore.setInfoPoint(point);
    commonStore.setIsInfoOpened(true);
  };

  return (
    <MapContainer
      className={c.mapContainer}
      center={mapStore.center}
      zoom={3}
      minZoom={3}
      maxBoundsViscosity={1.0}
      maxBounds={new LatLngBounds(new LatLng(-90, -180), new LatLng(90, 180))}>
      <LayersControl position="bottomleft">
        <LayersControl.BaseLayer name="Стандартная">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer checked name="Спутник">
          <TileLayer
            attribution="Tiles &copy; Esri"
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          />
        </LayersControl.BaseLayer>
      </LayersControl>

      <SetCenter coords={mapStore.center} isPassedCalculating={mapStore.isPassedCalculating} />
      {mapStore.separatedRoad.map((line, idx) => {
        return (
          <Fragment key={idx}>
            {line.length > 0 &&
              line
                .filter(p => !p.isPassed)
                .map(controlPoint => {
                  return (
                    <Circle
                      key={controlPoint.id}
                      center={[controlPoint.latitude, controlPoint.longitude]}
                      radius={80}
                      pathOptions={{ color: COLORS.PointColor }}
                    />
                  );
                })}


              {line.length > 0 && line.filter(p => p.isPassed).map(controlPoint =>
                  <Marker
                    key={controlPoint.id}
                    icon={pinPointIcon}
                    position={[controlPoint.latitude, controlPoint.longitude]}
                    eventHandlers={{
                      click: () => onControlPointClick(controlPoint)
                    }}>
                    <Popup key={controlPoint.id}>
                      <div className={c.pointPopup}>
                        <Typography className={c.headerPopup}>Контрольная точка</Typography>

                        <Typography className={c.countryPopup}>{controlPoint.country}</Typography>

                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => onOpenControlPointClick(controlPoint)}>
                          Подробнее
                        </Button>
                      </div>
                    </Popup>
                  </Marker>
                )}


              {/* TODO (BorukhinaKO) решено скрыть на данном этапе */}
              {/* {line.length > 0 && (
                <Polyline
                pathOptions={{ color: COLORS.PointLine, weight: 1, opacity: 0.7, dashArray: '5'}}
                positions={line.map(controlPoint => [controlPoint.latitude, controlPoint.longitude])}
                />
              )} */}
            </Fragment>
          );
        })}

        {notesStore.notes.map((line, idx) => 
          <div key={idx}>

              {line.map(note =>
                <Marker
                  key={note.id}
                  icon={note.id !== notesStore.lastNote?.id ? bookPointIcon : sheepPointIcon}
                  position={[note.latitude, note.longitude]}
                  zIndexOffset={10}
                  eventHandlers={{
                    click: () => onDairyNoteClick(note)
                  }}
                />
              )}


            {line.length > 0 && (
              <Polyline
                pathOptions={{ color: COLORS.NoteLine, weight: 2, opacity: 0.7, dashArray: '5' }}
                positions={line.map(note => [note.latitude, note.longitude])}
              />
            )}
          </div>
        )}
    </MapContainer>
  );
};

export default observer(Map);
