import { AxiosResponse } from 'axios';

import HTTPRequestService from '../HTTPRequestService';

import { IControlPointGetDto } from '../../Shared/Interfaces/IControlPointGetDto';

class ControlPointsApiService extends HTTPRequestService {
  public getAllPoints = (): Promise<AxiosResponse<IControlPointGetDto[]>> => {
    return this.GET('/ControlPoint');
  };

  public getSortedPoints = () : Promise<AxiosResponse<Array<IControlPointGetDto[]>>> => {
    return this.GET('/ControlPoint/sort');
  }

  public createPoint = (toAddPoint: any): Promise<AxiosResponse<IControlPointGetDto>> => {
    return this.POST('/ControlPoint', toAddPoint);
  };

  public updatePoint = (toUpdate: any): Promise<AxiosResponse<IControlPointGetDto>> => {
    return this.PUT('/ControlPoint', toUpdate);
  };

  public deletePoint = (id: number): Promise<AxiosResponse<IControlPointGetDto>> => {
    return this.DELETE('/ControlPoint/' + id);
  };
}

export default ControlPointsApiService;
