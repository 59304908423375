import { action, makeObservable, observable } from 'mobx';

import NotePointsApiService from '../Services/APIServices/NotePointsApiService';

import Note from 'Shared/Models/Note';

const notePointsApiService = new NotePointsApiService('/DiaryNotes');

class NotesStore {
  public waiting = false;

  public notes: Array<Note[]> = [];
  public allNotes: Note[] = [];
  public orderedNotes: Note[] = [];
  public lastNote: Note | null = null;

  public isNotesBarOpen: boolean = false;

  constructor() {
    makeObservable(this, {
      waiting: observable,
      notes: observable,
      allNotes: observable,
      orderedNotes: observable,
      lastNote: observable,
      isNotesBarOpen: observable,

      getNotes: action,
      setIsNotesBarOpen: action,
    });
  }

  /* Асинхронный метод, который сохраняет в стор точки маршрута с записями дневника*/
  public async getNotes() {
    try {
      this.waiting = true;

      const { data } = await notePointsApiService.getAllPoints();
      if (!data) return;

      this.notes = data.map((line, idx) => line.map(item => new Note(item)));

      const notes = [...this.notes[0].filter(n => !n.isFake), ...this.notes[1].filter(n => !n.isFake)];

      this.allNotes = notes;
      this.orderedNotes = notes.sort((a, b) => {
        return new Date(b.noteDate).getTime() - new Date(a.noteDate).getTime()
      });

      this.lastNote = this.allNotes[this.allNotes.length - 1];
    } catch (e) {
      console.log(e);
    } finally {
      this.waiting = false;
    }
  }

  public setIsNotesBarOpen(isOpen: boolean) {
    this.isNotesBarOpen = isOpen;
  }
}

export default new NotesStore();
