import { action, makeObservable, observable } from 'mobx';

import ControlPoint from 'Shared/Models/ControlPoint';
import Note from 'Shared/Models/Note';

class CommonStore {
  public isInfoOpend: boolean = false;

  public infoPoint: ControlPoint | Note | null = null;

  public activePoint: number | null = null;

  constructor() {
    makeObservable(this, {
      isInfoOpend: observable,
      infoPoint: observable,
      activePoint: observable,

      setIsInfoOpened: action,
      setInfoPoint: action,
      setActivePoint: action,
    });
  }

  /* Метод устонавливает открытие/закрытие окна с информацией*/
  public setIsInfoOpened(isOpened: boolean) {
    this.isInfoOpend = isOpened;
  }

  /* Метод устанавливает точку, информация о которой отображается в окне*/
  public setInfoPoint(info: ControlPoint | Note) {
    this.infoPoint = info;
  }

  public setActivePoint(id: number) {
    this.activePoint = id;
  }
}

export default new CommonStore();
