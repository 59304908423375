import { AxiosResponse } from 'axios';

import HTTPRequestService from '../HTTPRequestService';

import { INoteGetDto } from '../../Shared/Interfaces/INoteGetDto';

class NotePointsApiService extends HTTPRequestService {
  public getAllPoints = (): Promise<AxiosResponse<Array<INoteGetDto[]>>> => {
    return this.GET('/GetList');
  };

  public createPoint = (toAddPoint: any): Promise<AxiosResponse<INoteGetDto>> => {
    return this.POST('/Create', toAddPoint);
  };

  public updatePoint = (toUpdate: any): Promise<AxiosResponse<INoteGetDto>> => {
    return this.PUT('/Update', toUpdate);
  };

  public deletePoint = (id: number): Promise<AxiosResponse<INoteGetDto>> => {
    return this.DELETE('/Delete', null, {
      noteId: id,
    });
  };
}

export default NotePointsApiService;
