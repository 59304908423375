import { action, computed, makeObservable, observable } from 'mobx';

import moment from 'moment';

import { INoteGetDto } from 'Shared/Interfaces/INoteGetDto';

/* Класс описывает точку маршрута с записью дневника*/
class Note {
  public id: number = 0;
  public heading: string = '';
  public description: string | null = null;
  public noteDate: Date = new Date();
  public user: string = '';
  public longitude: number = 0;
  public latitude: number = 0;
  public depth: number | null = null;
  public temperature: number | null = null;
  public weather: string | null = null;
  public isFake: boolean = false;
  
  /** Расстояние с предыдущей точки */
  public distanceFromPrev: number = 0;
  
  constructor(dto?: INoteGetDto) {
    if (dto) {
      this.id = dto.id;
      this.heading = dto.heading;
      this.description = dto.description;
      this.noteDate = dto.dateNote;
      this.user = dto.user;
      this.longitude = dto.longitude;
      this.latitude = dto.latitude;
      this.depth = !dto.depth ||  dto.depth <= 0 ? dto.depth : 0;
      this.temperature = dto.temperature;
      this.weather = dto.weather;
      this.isFake = dto.isFake;
    }

    makeObservable(this, {
      heading: observable,
      description: observable,
      noteDate: observable,
      user: observable,
      longitude: observable,
      latitude: observable,
      depth: observable,
      temperature: observable,
      weather: observable,
      isFake: observable,

      updateFromDto: action,
      updateDistance: action,
      clear: action,

      noteDateString: computed,
      noteTemperatureString: computed,
      dateBefore: computed,
      noteWeatherIcon: computed,
    });
  }

  public get noteDateString() {
    return moment(this.noteDate).format('LL');
  }

  public get noteTemperatureString() {
    if (!this.temperature) return 'не измерено';

    if (Math.round(this.temperature) > 0) {
      return '+' + Math.round(this.temperature) + '°C';
    } else {
      return Math.round(this.temperature) + '°C';
    }
  }

  public get dateBefore() {
    let start = moment('2021-07-01');
    let end = moment(this.noteDate);
    let duration = moment.duration(end.diff(start));
    let days = duration.asDays();

    return Math.ceil(days);
  }

  public get noteWeatherIcon() {
    switch (this.weather) {
      case 'Clear':
        return '01d';
      case 'Clouds':
        return '03d';
      case 'Mist':
      case 'Smoke':
      case 'Haze':
      case 'Dust':
      case 'Fog':
      case 'Sand':
      case 'Ash':
      case 'Squall':
      case 'Tornado':
        return ' 50d';
      case 'Snow':
        return '13d';
      case 'Rain':
        return '10d';
      case 'Drizzle':
        return '09d';
      case 'Thunderstorm':
        return '11d';
      default:
        return '01d';
    }
  }

  public updateFromDto(dto: INoteGetDto) {
    this.id = dto.id;
    this.heading = dto.heading;
    this.description = dto.description;
    this.noteDate = dto.dateNote;
    this.user = dto.user;
    this.longitude = dto.longitude;
    this.latitude = dto.latitude;
    this.depth = !dto.depth || dto.depth <= 0 ? dto.depth : 0;    
    this.temperature = dto.temperature;
    this.weather = dto.weather;
    this.isFake = dto.isFake;
  }

  /**
   * Обновление расстояни
   * @param distanceFromPrev - расстояние в метрах
   */
  public updateDistance(distanceFromPrev: number) {
    this.distanceFromPrev = parseInt((distanceFromPrev / 1000).toString());
  }

  public clear() {
    this.heading = '';
    this.description = '';
    this.noteDate = new Date();
    this.user = '';
    this.longitude = 0;
    this.latitude = 0;
    this.depth = null;
    this.temperature = null;
    this.weather = null;
  }

  public getDto(): INoteGetDto {
    return {
      id: this.id,
      heading: this.heading,
      description: this.description,
      dateNote: this.noteDate,
      user: this.user,
      longitude: this.longitude,
      latitude: this.latitude,
      depth: this.depth,
      temperature: this.temperature,
      weather: this.weather,
      isFake: this.isFake,
    };
  }
}

export default Note;
