import { makeStyles } from '@material-ui/core';
import TempImage from 'assets/temperature.jpg';
import DepthImage from 'assets/depth.jpg';
import DaysImage from 'assets/days.jpg';
import DistanceImage from 'assets/distance2.jpg';

const useStyles = makeStyles(theme => ({
  noteInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    backdropFilter: 'blur(5px)',
    width: '100%',
    height: '100%',
    zIndex: 4,
  },
  card: {
    position: 'relative',
    width: '95%',
    height: '95%',
    overflowY: 'scroll',
    textAlign: 'center',
  },
  closeBtn: {
    padding: '15px 0',
    position: 'fixed',
    top: '2%',
    right: '4%',
    minWidth: 20,
  },
  header: {
    margin: 0,
    textAlign: 'center',
  },
  subTitle: {
    fontSize: '14px',
  },
  cardContent: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '& ul': {
      display: 'inline-block',
      textAlign: 'left',
      lineHeight: 2,
      margin: 0,
    },
    '& img': {
      maxWidth: '100%',
    },
  },
  cardInfos: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  cardPanel: {
    position: 'relative',
    height: 138,
    width: 210,   
    borderRadius: 7,
    padding: 14,
    margin: 10,
    boxShadow: '10px 10px 20px #bbb',
    color: '#fff',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '125px',
    },
  },
  weatherGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  temperatureCard: {    
    background: `url(${TempImage})`,
    backgroundSize: 'cover',
  },
  daysCard: {
    background: `url(${DaysImage})`,
    backgroundSize: 'cover',
  },
  distanceCard: {
    background: `url(${DistanceImage})`,
    backgroundSize: 'cover',
  },
  depthCard: {    
    background: `url(${DepthImage})`,
    backgroundSize: 'cover',
  },

  cardValue: {
    marginTop: 10,
    fontSize: 28,
    fontWeight: 500,
  },
  
  noDepth: {
    fontSize: '25px',
  },
  cardInfosContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    zIndex: 3,
  },
  wrapper: {
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0,0,0,.4)',
    borderRadius: '7px',
    zIndex: 2,
  },
  descCard: {
    textAlign: 'unset',
  },
  descHeader: {
    marginTop: '35px',
  },
  descBody: {
    '& img': {
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%!important',
        height: 'unset!important',
      },
    },
  },
  geoSource: {
    color: 'white',
    textDecoration: 'none',
    '&:focus, &:hover': {
      borderBottom: '1px solid white',
    }
  }
}));

export default useStyles;
