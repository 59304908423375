import { useEffect } from 'react';
import { observer } from 'mobx-react';

import { mapStore, notesStore } from 'Stores';

import { Button, Tooltip, withStyles } from '@material-ui/core';
import { Book, Close } from '@material-ui/icons';

import useStyles from './styles';
import MapComponent from './Components/Map/Map';
import NotesBar from './Components/NotesBar/NotesBar';
import InfoPanel from './Components/InfoPanel/InfoPanel';
import MapLegend from 'Components/MapLegend';
import rubiusLogo from 'assets/rubius_logo.png';

export const StyledTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 13,
  },
}))(Tooltip);

//TODO (BorukhinaKO) Какие ещё увидела здесь проблемы - панель InfoPanel рисуется поверх карты, а не сдвигает её, соответственно не видно маркеры
const MainPage = () => {
  const c = useStyles();

  useEffect(() => {
    mapStore.getSeparatedRoad();
    notesStore.getNotes();
  }, []);

  return (
    <div className={c.wrapper}>
      <StyledTooltip title={notesStore.isNotesBarOpen ? 'Закрыть дневник' : 'Открыть дневник'} placement="left">
        <Button
          className={c.diaryButton}
          variant="contained"
          onClick={() => notesStore.setIsNotesBarOpen(!notesStore.isNotesBarOpen)}
          color={notesStore.isNotesBarOpen ? 'secondary' : 'primary'}>
          {notesStore.isNotesBarOpen ? <Close /> : <Book />}
        </Button>
      </StyledTooltip>

      <MapComponent />

      <InfoPanel />

      <MapLegend/>

      <NotesBar isOpend={notesStore.isNotesBarOpen} />
    </div>
  );
};

export default observer(MainPage);
