import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  visible: {
    position: 'absolute',
    left: '100%',
    top: '0',
    width: '450px',
    height: '100%',
    background: '#fff',
    transition: theme.transitions.create(['transform'], {
      duration: 450,
      easing: theme.transitions.easing.easeOut,
    }),
    transform: 'translateX(-100%)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  hidden: {
    position: 'absolute',
    left: '100%',
    top: '0',
    width: '450px',
    height: '100%',
    background: '#fff',
    transition: theme.transitions.create(['transform'], {
      duration: 350,
      easing: theme.transitions.easing.easeOut,
    }),
    transform: 'translateX(0)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  barHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '20px',
    height: '70px',
    background: '#eee',
    color: '#333',
    fontWeight: 'bold',
    fontSize: '20px',
  },
  notesWrapper: {
    height: 'calc(100vh - 110px)',
    overflowY: 'scroll',
  },
  emptyNotes: {
    textAlign: 'center',
    marginTop: 40,
    color: '#777',
  },
  linkToDeveloper: {
    textAlign: 'center',
    margin: 0,
    paddingTop: 6,
    borderTop: "1px solid #eee",
  },
  link: {
    color: `black !important`,
    textDecoration: 'none',
    marginRight: 5,
  },
  linkIcon:{
    width: 15,
  }
}));

export default useStyles;
