import axios, { AxiosResponse } from 'axios';

import EMethod from './EMethod';

class HTTPRequestService {
  private readonly baseUrl: string = process.env.PUBLIC_URL + '/api';

  constructor(baseUrl: string) {
    this.baseUrl = this.baseUrl + baseUrl;

    const devUrl = localStorage.getItem('ww-trip-back');

    if (devUrl) this.baseUrl = devUrl + '/api' + baseUrl;
  }

  public GET = (url: string, data?: any, params?: any) => {
    return this.doRequest(EMethod.GET, url, data, params);
  };

  public POST = (url: string, data?: any, params?: any) => {
    return this.doRequest(EMethod.POST, url, data, params);
  };

  public PUT = (url: string, data?: any, params?: any): Promise<any> => {
    return this.doRequest(EMethod.PUT, url, data, params);
  };

  public DELETE = (url: string, data?: any, params?: any): Promise<any> => {
    return this.doRequest(EMethod.DELETE, url, data, params);
  };

  private async doRequest(method: EMethod, url: string, data?: any, params?: any): Promise<AxiosResponse> {
    const headers = {
      Accept: 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    };

    try {
      return await axios.request({ method, baseURL: this.baseUrl, url, headers, data, params });
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
}

export default HTTPRequestService;
