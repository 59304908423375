import { action, computed, makeObservable, observable } from 'mobx';

import moment from 'moment';

import { IControlPointGetDto } from 'Shared/Interfaces/IControlPointGetDto';

/* Класс описывает контрольную точку маршрута */
class ControlPoint {
  public id: number = 0;
  public description: string | null = null;
  public actualDate?: Date = new Date();
  public expectedDate?: Date = new Date();
  public country: string | null = null;
  public longitude: number = 0;
  public latitude: number = 0;
  public isPassed: boolean = false;

  /** Расстояние с предыдущей точки */
  public distanceFromPrev: number = 0;

  constructor(dto?: IControlPointGetDto) {
    if (dto) {
      this.id = dto.id;
      this.description = dto.description;
      this.actualDate = dto.actualDate;
      this.expectedDate = dto.expectedDate;
      this.country = dto.country;
      this.longitude = dto.longitude;
      this.latitude = dto.latitude;
    }

    makeObservable(this, {
      description: observable,
      actualDate: observable,
      expectedDate: observable,
      country: observable,
      longitude: observable,
      latitude: observable,
      isPassed: observable,
      distanceFromPrev: observable,

      updateFromDto: action,
      updateIsPassed: action,
      clear: action,

      actualDateString: computed,
      expectedDateString: computed,
    });
  }

  public get actualDateString() {
    return moment(this.actualDate).format('LL');
  }

  public get expectedDateString() {
    return moment(this.expectedDate).format('LL');
  }

  public updateIsPassed(isPassed: boolean) {
    this.isPassed = isPassed;
  }

  public updateFromDto(dto: IControlPointGetDto) {
    this.id = dto.id;
    this.description = dto.description;
    this.actualDate = dto.actualDate;
    this.expectedDate = dto.expectedDate;
    this.country = dto.country;
    this.longitude = dto.longitude;
    this.latitude = dto.latitude;
  }

  public clear() {
    this.description = null;
    this.actualDate = undefined;
    this.expectedDate = undefined;
    this.country = null;
    this.longitude = 0;
    this.latitude = 0;
  }

  public getDto(): IControlPointGetDto {
    return {
      id: this.id,
      description: this.description,
      actualDate: this.actualDate,
      expectedDate: this.expectedDate,
      country: this.country,
      longitude: this.longitude,
      latitude: this.latitude,
    };
  }
}

export default ControlPoint;
