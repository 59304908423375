import React from 'react';
import { observer } from 'mobx-react';

import { Tooltip } from '@material-ui/core';
import { LocationOnOutlined, LocationOn, LibraryBooks } from '@material-ui/icons';

import { mapStore, commonStore } from 'Stores';

import { LatLng } from 'leaflet';

import useStyles from './styles';
import Note from 'Shared/Models/Note';

interface IProps {
  note: Note;
}

const NoteItem = ({ note }: IProps) => {
  const c = useStyles();

  const onLocationClick = () => {
    mapStore.setCenter(new LatLng(note.latitude, note.longitude));
    commonStore.setActivePoint(note.id);
  };

  const getTemperatureClass = (temperature: number): string => {
    switch (true) {
      case temperature > 30:
        return c.info__temperature_hot;
      case temperature > 0:
        return c.info__temperature_warm;
      case temperature < 30:
        return c.info__temperature_cold;
      default:
        return c.info__temperature_cool;
    }
  };

  const getReliefElement = (depth: number) => {
    switch (true) {
      case depth > 0:
        return <div className={`${c.info__temperature} ${c.info__height} `}>{depth + 'м'}</div>;
      case depth <= 0:
        return <div className={`${c.info__temperature} ${c.info__depth} `}>{Math.abs(depth) + 'м'}</div>;
      default:
        return <></>;
    }
  };

  return (
    <div className={c.note}>
      <div className={c.info}>
        <div className={c.info__header}>
          {note.noteDateString}
          <span className={c.info_coordinates}>
            <div>{note.latitude}°</div>
            <div>{note.longitude}°</div>
          </span>
          <Tooltip title="Посмотреть на карте">
            {note.id === commonStore.activePoint ? (
              <LocationOn className={c.icon} color="primary" onClick={onLocationClick} />
            ) : (
              <LocationOnOutlined className={c.icon} color="primary" onClick={onLocationClick} />
            )}
          </Tooltip>
          <Tooltip title="Посмотреть информацию">
            <LibraryBooks
              className={c.icon}
              color="primary"
              onClick={() => {
                commonStore.setInfoPoint(note);
                commonStore.setIsInfoOpened(true);
              }}
            />
          </Tooltip>
        </div>

        <div className={c.widgets}>
          {note.temperature && (
            <Tooltip title="Температура воздуха">
              <div className={`${c.info__temperature} ${getTemperatureClass(note.temperature)}`}>
                {note.noteTemperatureString}
              </div>
            </Tooltip>
          )}

          {note.depth && <Tooltip title="Глубина океана">{getReliefElement(note.depth)}</Tooltip>}
        </div>
      </div>
      <div className={c.description}>
        <span className={c.header}>{note.heading}</span> –{' '}
        {note.description?.replace(/<[^>]+>/g, '').replace(/&[^>]+;/g, ' ')}
      </div>
    </div>
  );
};

export default observer(NoteItem);
