import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  bottomPosition: {
    position: 'absolute',
    right: 8,
    bottom: 25,    
  },
  content: {
    paddingLeft: 30,
    paddingBottom: 24,
    display: 'flex',
    flexDirection: 'column',    
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    maxWidth: 256,
    maxHeight: 252,
    borderRadius: 4,
  },
  helpIcon: {
    width: 24,
    height: 24,
  },
  statusRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 7,
    alignItems: 'center',
    minHeight: 45,
  },
  line:{
    marginTop: 8,
    width: 42,
    height: 4,
    border: 'none',
    borderTop:'6px dashed',
  },
  dot: {
    height: 28,
    width: 28,
    margin: 8,
    borderRadius: '50%',
    display: 'inline-block',
  },
  name: {
    fontSize: 14,
    color: '#2D314B',
    paddingTop: 4,
    marginLeft: 12,
  },
  legendIcon: {
    width: 28,
    margin: 8,
  },
  legendButton: {
    backgroundColor: '#f4f4f4',
    padding: 0,
    minWidth: 44,
    height: 44,
  },
  closeButton: {
    padding: 0,
    minWidth: 24,
    height: 24,
    position: 'absolute',
    right: '1%',
    top: '2%',
    bottom: 25, 
  },
}));

export default useStyles;
