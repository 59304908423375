import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  note: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '15px',
    boxSizing: 'border-box',
    borderBottom: '1px #bbb solid',
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '14px',
  },
  info__header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  info_coordinates: {
    color: '#888',
    marginLeft: '8px',
    fontSize: '13px',
  },
  info__temperature: {
    padding: '5px 8px 5px 5px',
    borderRadius: '15%',
    color: '#fff',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  info__temperature_hot: {
    backgroundColor: '#9e2f2f',
  },
  info__temperature_warm: {
    backgroundColor: '#4d982b',
  },
  info__temperature_cool: {
    backgroundColor: '#4782b3',
    color: '#000',
  },
  info__temperature_cold: {
    backgroundColor: '#003b82',
  },
  info__depth: {
    backgroundColor: '#2f5c92',
  },
  info__height: {
    backgroundColor: '#9e672f',
  },
  info__noDepth: {
    backgroundColor: '#909090',
  },
  widgets: {
    display: 'flex',
    '& div': {
      marginLeft: '10px',
      height: '20px',
    },
  },
  description: {
    marginTop: '10px',
    color: '#333',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    display: 'box',
    boxOrient: 'vertical',
    overflow: 'hidden',
    lineHeight: '1.5',
  },
  icon: {
    fontSize: 22,
    marginLeft: 5,
  },
  header: {
    fontWeight: 'bold',
  },
}));

export default useStyles;
